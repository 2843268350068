import React, { useEffect, useState } from 'react';
import { getFirstAvailableUnit, getIframeURL } from '../hooks/utils/iframe';
import { getParameterByName } from '../hooks/helpers';
import { useParams } from 'react-router-dom';

const CourseIframeSideItem = ({
  courseData,
  setEdxIframe,
  changeIframeLoadingStatus,
  unitIdFromUrl,
  setUnitIdFromUrl,
}) => {
  const { courseID } = useParams();
  const [expandedSections, setExpandedSections] = useState({});

  const handleAccordionClick = (index) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [index]: !prevExpandedSections[index],
    }));
  };

  const handleIframeUrl = (url) => {
    setEdxIframe(url);
  };

  const setHighlightOnMenu = () => {
    const unitId =
      getParameterByName('unit_id') || getFirstAvailableUnit(courseData).id;
    setUnitIdFromUrl(unitId);
  };

  const handleOutlineClick = (parentIndex, event) => {
    const dataId = event.currentTarget.getAttribute('data-id');
    const currentUnitId = getParameterByName('unit_id');

    const firstUnitId = getFirstAvailableUnit(courseData).id;

    // If there's no unit_id in the URL and this is  the first element, return early
    if (!currentUnitId && dataId === firstUnitId) {
      return;
    }
    if (dataId !== getParameterByName('unit_id')) {
      const newURL = `/courses/${courseID}/course?unit_id=${dataId}`;
      changeIframeLoadingStatus(true);
      window.history.pushState(null, 'Course', newURL);
      setHighlightOnMenu();
      getIframeURL(courseID, courseData, handleIframeUrl);
    }
  };

  useEffect(() => {
    getIframeURL(courseID, courseData, handleIframeUrl);
    setHighlightOnMenu();
  }, [courseData]);

  return (
    <>
      {courseData?.children?.map((item, index) => (
        <div className="faq-question-wrap-3" key={index}>
          <a
            href="#"
            className="faq-question-bar-3 w-inline-block"
            onClick={() => handleAccordionClick(index)}
          >
            <div className="div-block-632 w-clearfix">
              <div
                className={`faq-wrap-2 section ${expandedSections[index] ? 'open' : ''}`}
              >
                <div className="f_a">
                  <strong className="bold-text-9"></strong>
                </div>
              </div>
              <div className="question-title-4 f">{item.display_name}</div>
            </div>
          </a>
          {Array.isArray(item.children) && item.children.map((childItem, parentIndex) => {

            if (Array.isArray(childItem.children) && childItem.children.length > 0) {
              return childItem.children.map((innerChildItem, innerIndex) => {
                const isActive = unitIdFromUrl === innerChildItem.id;

                return (
                  <div
                    key={`${parentIndex}-${innerIndex}`}
                    className={`faq-content-3 ${expandedSections[index] ? 'open' : ''}`}
                  >
                    <div className={`inner ${expandedSections[index] ? 'open' : ''}`}>
                      <div
                        data-id={innerChildItem.id}
                        className={`module_row ${isActive ? 'active' : ''}`}
                        onClick={(event) => handleOutlineClick(parentIndex, event)}
                      >
                        <div className="div-block-632 w-clearfix">
                          <div className="inner_text">
                            <a
                              href="#"
                              className={`c_l ${isActive ? 'active-link' : ''}`}
                            >
                              {childItem.display_name}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
            } else {

              const isActive = unitIdFromUrl === childItem.id;

              return (
                <div
                  key={parentIndex}
                  className={`faq-content-3 ${expandedSections[index] ? 'open' : ''}`}
                >
                  <div className={`inner ${expandedSections[index] ? 'open' : ''}`}>
                    <div
                      data-id={childItem.id}
                      className={`module_row ${isActive ? 'active' : ''}`}
                      onClick={(event) => handleOutlineClick(parentIndex, event)}
                    >
                      <div className="div-block-632 w-clearfix">
                        <div className="inner_text">
                          <a href="#"
                             className={`c_l ${isActive ? 'active-link' : ''}`}>
                            {childItem.display_name}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}


        </div>
      ))}
    </>
  );
};

export default CourseIframeSideItem;
