import Contentfilterbox from '../../components/Contentfilterbox/Contentfilterbox';
import React, { useEffect, useState } from 'react';

import Coursesfilterbox from '../../components/Coursesfilterbox';
import ReactPaginate from 'react-paginate';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import {
  getDiscoverItemsAPI,
  getFacetItemsAPI,
  getQueryParamValue,
} from '../../hooks/helpers';
import useDiscover from '../../hooks/useDiscover';

const BrowseContentView = () => {
  const [
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
  ] = useDiscover();
  const [currentPage, setCurrentPage] = useState(1);
  const [contentFilterBoxItems, setContentFilterBoxItems] = useState({});

  useEffect(() => {
    getFacetItemsAPI(setContentFilterBoxItems);
  }, []);

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    getDiscoverItemsAPI(
      discoverCallback,
      selected + 1,
      selectedTerms,
      getQueryParamValue()
    );
  };

  const facetHandler = (newSelectedTerms) => {
    getDiscoverItemsAPI(
      discoverCallback,
      currentPage,
      newSelectedTerms,
      getQueryParamValue()
    );
  };

  return (
    <span>
      <div className="panels">
        <div className="panels_cont">
          <div className="panel-browse panel">
            <div className="browse">
              <div className="browse_panel active" data-name="browse">
                <div className="browse_cont">
                  <div className="browse_left">
                    <div className="prof_box browse">
                      <div no-mapping="" className="text-block sec_title ex_co">
                        Explore Content
                      </div>
                      <div no-mapping="" className="text-block sec_title fb">
                        Filter By
                      </div>

                      <Contentfilterbox
                        discoverItems={contentFilterBoxItems}
                        selectedTerms={selectedTerms}
                        setSelectedTerms={setSelectedTerms}
                        facetHandler={facetHandler}
                        setDiscoverLoaded={setDiscoverLoaded}
                        isLoading={!discoverLoaded} // Pass loading state
                      />
                    </div>

                    <div className="prof_box grey s hidden">
                      <div className="div-block-376">
                        <img
                          alt=""
                          className="image-127"
                          loading="lazy"
                          src="images/plus-2_1plus-2.png"
                        />
                      </div>
                      <div className="text-block sec_title ex_co amc">
                        Add My Content
                      </div>
                    </div>
                  </div>
                  <div className="br_panel" data-name="top">
                    <div className="text-block-308">
                      Featured Learning Content
                    </div>
                    {!discoverLoaded ? (
                      <IBLSpinLoader size={40} containerHeight={'300px'} />
                    ) : (
                      <>
                        <div className="div-block-430 sl pathway_grid">
                          <Coursesfilterbox
                            discoverItems={discoverItems}
                            discoverLoaded={discoverLoaded}
                          />
                        </div>
                        <ReactPaginate
                          onPageChange={paginate}
                          pageCount={discoverItems.num_pages}
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          containerClassName={'pagination'}
                          pageLinkClassName={'page-number'}
                          previousLinkClassName={'page-number'}
                          nextLinkClassName={'page-number'}
                          activeLinkClassName={'active'}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default BrowseContentView;
