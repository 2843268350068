import React from 'react';
import {
  getFirstAvailableUnit,
  getParentBlockById,
} from '../../hooks/utils/iframe';
import { getParameterByName } from '../../hooks/helpers';
import './CourseIframeSecondaryMenu.css'


function getDisplayName(courseData, indices) {
  let current = courseData;
  for (let index of indices) {
    if (current && current.children && current.children[index]) {
      current = current.children[index]; // Navigate to the next level
    } else {
      return undefined;
    }
  }
  return current.display_name;
}

;

const CourseIframeSecondaryMenu = ({ courseData, edxIframe }) => {

  const openInWindow = () => {
    window.open(edxIframe, '_blank');
  }

  let data = null;
  if (getParameterByName('unit_id') === null) {
    data = getParentBlockById(
      courseData.children,
      getFirstAvailableUnit(courseData).id
    );
  } else {
    data = getParentBlockById(
      courseData.children,
      getParameterByName('unit_id')
    );
  }
  let indices = data.foundIndices;

  return (
    <div className="secondary_menu">
      <div className="secondary_options">
        <a href="#" id="about" className="secondary_link">
          {courseData.children[indices[0]].display_name}
        </a>
        <div className="f_a dr">
          <strong className="bold-text-8 dr"></strong>
        </div>
        <a href="#" id="about" className="secondary_link">
          {courseData.children[indices[0]].children[indices[1]].display_name}
        </a>

        <div className="f_a dr">
          <strong className="bold-text-8 dr"></strong>
        </div>
        <a href="#" id="about" className="secondary_link">

          {
            getDisplayName(courseData, indices)
          }
        </a>
        <img
          data-w-id="7ab16dd6-faec-d198-fc4d-b89a9f4b2af0"
          src="/images/fullscreen-svgrepo-com.svg"
          onClick={openInWindow}
          className="banner-btn w-button">
        </img>

      </div>

    </div>
  );
};
export default CourseIframeSecondaryMenu;
