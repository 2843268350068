import React, { useState } from 'react';
import './Contentfilterbox.css';

const Contentfilterbox = ({
  discoverItems,
  selectedTerms,
  setSelectedTerms,
  facetHandler,
  setDiscoverLoaded,
  isLoading, // New prop for loading state
}) => {
  const convertFacetData = (facetData) => {
    const enableDurationFacet =
      process.env.REACT_APP_IBL_ENABLE_DURATION_FACET !== 'false';

    return Object.entries(facetData?.facet || {}).reduce(
      (acc, [facetName, facetInfo]) => {
        if (facetName === 'duration' && !enableDurationFacet) return acc;

        const facetObject = {
          name: facetName,
          total: facetInfo.total,
          terms: Object.entries(facetInfo.terms).map(([term, count]) => ({
            term,
            count,
          })),
        };

        if (facetObject.terms.length > 0) {
          acc.push(facetObject);
        }

        return acc;
      },
      []
    );
  };

  const [facetVisibility, setFacetVisibility] = useState({});
  const [filterText, setFilterText] = useState({});

  const handleFacetClick = (facetName) => {
    setFacetVisibility((prevVisibility) => ({
      ...prevVisibility,
      [facetName]: !prevVisibility[facetName],
    }));
  };

  const handleTermClick = (facetName, term) => {
    setDiscoverLoaded(false);
    let processedTerm = term;

    if (term.toLowerCase() === 'self-paced') {
      processedTerm = 'self_paced true';
    } else if (term.toLowerCase() === 'instructor-led') {
      processedTerm = 'self_paced false';
    } else if (term.toLowerCase() === 'en') {
      processedTerm = 'language en';
    }

    setSelectedTerms((prevSelectedTerms) => {
      let newSelectedTerms;
      if (Array.isArray(processedTerm)) {
        if (
          processedTerm.every((duration) =>
            prevSelectedTerms.includes(duration)
          )
        ) {
          newSelectedTerms = prevSelectedTerms.filter(
            (selectedTerm) => !processedTerm.includes(selectedTerm)
          );
        } else {
          newSelectedTerms = [...prevSelectedTerms, ...processedTerm];
        }
      } else {
        newSelectedTerms = prevSelectedTerms.includes(processedTerm)
          ? prevSelectedTerms.filter(
              (selectedTerm) => selectedTerm !== processedTerm
            )
          : [...prevSelectedTerms, processedTerm];
      }
      facetHandler(newSelectedTerms);
      return newSelectedTerms;
    });
  };

  const handleInputChange = (facetName, e) => {
    const value = e.target.value;
    setFilterText((prevFilterText) => ({
      ...prevFilterText,
      [facetName]: value,
    }));
  };

  const isTermSelected = (facetName, term) => {
    const processedTerm =
      term.toLowerCase() === 'self-paced'
        ? 'self_paced true'
        : term.toLowerCase() === 'instructor-led'
          ? 'self_paced false'
          : term.toLowerCase() === 'en'
            ? 'language en'
            : term;

    return selectedTerms.includes(processedTerm);
  };

  return (
    <div
      data-testid="content-filter-box-container"
      className={isLoading ? 'greyed-out' : ''}
    >
      {convertFacetData(discoverItems).map((facet, index) => (
        <div className="f_box" key={index}>
          <div className="f_row" onClick={() => handleFacetClick(facet.name)}>
            <div prop-data-value-label="Type" prop-data-names="label">
              {facet.name}
            </div>
            <img
              src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bac9f_plus.png"
              loading="lazy"
              alt="Expand"
              className="img_v"
              style={{
                display: facetVisibility[facet.name] ? 'none' : 'inline-block',
              }}
            />
            <img
              src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bacc9_minus-sign.png"
              loading="lazy"
              alt="Collapse"
              className="img_h"
              style={{
                display: facetVisibility[facet.name] ? 'inline-block' : 'none',
              }}
            />
          </div>
          <div
            className="form-block filter w-form"
            style={{ display: facetVisibility[facet.name] ? 'block' : 'none' }}
          >
            <form id="email-form" name="email-form" className="form filter">
              <div className="div-block-9">
                <input
                  className="text-field w-input"
                  maxLength={256}
                  name="filter-facet-terms"
                  placeholder="Filter"
                  type="text"
                  id="filter-facet-terms"
                  value={filterText[facet.name] || ''}
                  onChange={(e) => handleInputChange(facet.name, e)}
                />
                <div className="div-block">
                  <img
                    src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66baba3_loupe.png"
                    loading="lazy"
                    alt="Search Icon"
                    className="image-2"
                  />
                </div>
              </div>
              {facet.terms
                .filter((term) =>
                  term.term
                    .toLowerCase()
                    .includes((filterText[facet.name] || '').toLowerCase())
                )
                .map((term, termIndex) => (
                  <label key={termIndex} className="w-checkbox checkbox-field">
                    <input
                      type="checkbox"
                      id={`checkbox-${index}-${termIndex}`}
                      name={`checkbox-${index}-${termIndex}`}
                      className="w-checkbox-input checkbox"
                      checked={isTermSelected(facet.name, term.term)}
                      onChange={() => handleTermClick(facet.name, term.term)}
                    />
                    <span
                      className="checkbox-label w-form-label"
                      htmlFor={`checkbox-${index}-${termIndex}`}
                    >
                      {`${term.term} (${term.count})`}
                    </span>
                  </label>
                ))}
            </form>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Contentfilterbox;
