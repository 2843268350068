import { Link } from 'react-router-dom';
import { getRandomImage } from '../hooks/helpers';

const Coursesfilterbox = ({ discoverItems, discoverLoaded }) => {


  const getImageURL = (data) =>{
    let imageUrl = ""

    try{
      const edxData = JSON.parse(data?._source?.edx_data)
      imageUrl = process.env.REACT_APP_IBL_LMS_URL + edxData?.course_image_asset_path;
    }
    catch (e) {
      console.log("Failed to parse edx_data or construct image URL:", e);

    }
    return imageUrl
    }


  const handleImageError = (e) => {
    e.target.src = getRandomImage();
  };

  const determineItemType = (item) => {
    // You need to implement the logic to determine the item type based on your data
    // For example, you might check if the item has certain properties to identify its type
    if (item === 'ibl-dm.public.dl_catalog_app_pathway') {
      return 'PATHWAY';
    } else if (item === 'ibl-dm.public.dl_catalog_app_program') {
      return 'PROGRAM';
    } else {
      // If no specific type is found, default to "Course"
      return 'COURSE';
    }
  };

  const determineLabelClass = (itemType) => {
    switch (itemType) {
      case 'PROGRAM':
        return 'label d';
      case 'COURSE':
        return 'label c';
      case 'PATHWAY':
        return 'label h';
      default:
        return 'label c'; // Default class if needed
    }
  };

  const determineURL = (item, itemType) => {
    switch (itemType) {
      case 'PROGRAM':
        return `/programs/${item._id}`;
      case 'COURSE':
        return `/courses/${item._source?.course_id || item?.course_id}`;
      case 'PATHWAY':
        return `/pathways/${item._source?.pathway_id}`;
      default:
        return '#'; // Default URL if needed
    }
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n\n                ul {\n                list-style: none;\n            }\n\n                .pagination {\n               display: flex;\n    justify-content: flex-end;\n            }\n\n                .pagination .page-number {\n               padding: 2px 10px;\n    border-radius: 4px;\n    font-size: 14px;\n    cursor: pointer;\n    margin: 0 4px;\n    align-items: center;\n    justify-content: center;\n            }\n\n                .pagination .page-number:hover {\n                background-image: linear-gradient(90deg, #4a78b8, #6cafe1);\n    color: #fff;\n            }\n\n                .pagination .active {\n               background-image: linear-gradient(90deg, #4a78b8, #6cafe1);\n    color: #fff;\n            }\n\n                .pagination .active:hover {\n                color: #00a8ff;\n                background: #fff;\n            }\n            ',
        }}
      />

      {discoverLoaded &&
        discoverItems?.results?.map((item, index) => {
          const itemType = determineItemType(item?._index || item);
          const labelClass = determineLabelClass(itemType); // Determine the label class based on item type
          const url = determineURL(item, itemType); // Determine the URL based on item type
          const imageUrl = getImageURL(item)



          return (
            <div
              key={index}
              className="item-box path_box"
              id={`w-node-_9d122df3-5436-acb4-d16f-30b76ec43f9d-27264aa9`}
            >
              <Link className="link-block-31 w-inline-block" to={url}>
                <div className="div-block-411">
                  <img
                    alt=""
                    className="image-155"
                    loading="lazy"
                    sizes="(max-width: 767px) 234px, (max-width: 991px) 295.734375px, (max-width: 1279px) 21vw, 226.5px"
                    onError={handleImageError}
                    src={imageUrl}
                  />
                  <div className={labelClass}>
                    <div className="text-block-294">{itemType}</div>
                  </div>
                </div>
                <div className="item-body">
                  <div className="div-block-417">
                    <div className="div-block-461">
                      <img
                        alt=""
                        className="image-167"
                        loading="lazy"
                        sizes="100vw"
                        src="images/google.png"
                        srcSet="images/google-p-500.png 500w, images/google.png 512w"
                      />
                    </div>
                    <div className="text-block-235">Google</div>
                    <div className="div-block-523">
                      <div className="text-block-233">120 Skill Coins</div>
                    </div>
                  </div>
                  <div className="text-block-225">
                    {item?._source?.name || item.name}
                  </div>
                  <div className="div-block-470">
                    <div className="text-block-273">
                      <span className="skill_span">Computer Programming</span>
                      <span className="skill_span">Python Libraries</span>
                      <span className="skill_span last">
                        Python Programming
                      </span>
                    </div>
                  </div>
                  <div className="div-block-387">
                    <img
                      alt=""
                      className="image-184"
                      loading="lazy"
                      sizes="100vw"
                      src="images/Screenshot-2022-06-07-at-7.25.09-PM.png"
                      srcSet="images/Screenshot-2022-06-07-at-7.25.09-PM-p-500.png 500w, images/Screenshot-2022-06-07-at-7.25.09-PM-p-800.png 800w, images/Screenshot-2022-06-07-at-7.25.09-PM-p-1080.png 1080w, images/Screenshot-2022-06-07-at-7.25.09-PM.png 1343w"
                    />
                    <div className="text-block-241">(1k+)</div>
                  </div>
                  <div className="div-block-389">
                    <div className="text-block-287">
                      <strong className="bold-text-7 p"> </strong>
                      <span className="bullet">
                        <strong className="bold-text-7"></strong>
                      </span>
                      <strong className="bold-text-7"></strong>
                    </div>
                    <div className="item_options">
                      <div className="item_options_triger">
                        <img
                          alt=""
                          className="image-182"
                          loading="lazy"
                          src="images/menu_1menu.png"
                        />
                      </div>
                      <div className="options_div">
                        <div className="text-block-238">Add to Pathway</div>
                        <div className="text-block-238">Save for Later</div>
                        <div className="text-block-238">Dismiss</div>
                        <div className="text-block-238">Share</div>
                        <div className="text-block-238">Create a Pathway</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
};

export default Coursesfilterbox;
