import React from 'react';
import './Focusskillbox.css';
import { Link } from 'react-router-dom';
import NoItemView from '../NoItemView';
import { GLOBAL_COLORS, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { FaPlus } from 'react-icons/fa6';

const Focusskillbox = (props) => {
  return (
    <div className="prof_box">
      <div className="div-block-15">
        <div className="text-block sec_title">Latest Skills</div>
        <Link className="link-block-5 w-inline-block" to="/profile/skills">
          <FaPlus className="image-15" color={GLOBAL_COLORS.icon_color} />
        </Link>
      </div>
      <div>
        {!props?.userSkillsDataLoaded ? (
          <IBLSpinLoader size={40} containerHeight={'100px'} />
        ) : props?.userSkillsData?.length > 0 ? (
          props?.userSkillsData?.slice(0, 5)?.map((skill, index) => (
            <div className="div-block-17 al_l" key={index}>
              <div className="div-block-16">
                <div className="text-block-12">{skill?.name}</div>
              </div>
            </div>
          ))
        ) : (
          props?.userSkillsDataLoaded && (
            <NoItemView
              imageUrl={'images/skills_icon_1skills_icon.png'}
              displayMessage={'No skills yet.'}
            />
          )
        )}
      </div>

      <div className="div-block-18 hidden">
        <a
          className="link-block-4 w-inline-block"
          href="https://ibl-skills-v7.webflow.io/profile#skills"
        >
          <div className="text-block-13">Manage Skills</div>
          <img
            alt=""
            className="image-15 rc"
            loading="lazy"
            src="images/chevron_1chevron.png"
          />
        </a>
        <a className="link-block-4 start w-inline-block" href="start.html">
          <div className="text-block-13"> Re-Evaluate</div>
          <img
            alt=""
            className="image-15 rc"
            loading="lazy"
            src="images/chevron_1chevron.png"
          />
        </a>
      </div>
    </div>
  );
};

export default Focusskillbox;
