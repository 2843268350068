"use strict";
/**
 * @namespace ibldmcourses
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCourseSkillPointInfo = exports.getUserCourseCompletion = exports.createAttendance = exports.checkIfUserIsInvited = exports.fetchUserProgress = exports.getUserCourses = void 0;
/**
 * This callback type is called `successCb` and is called when an api request is successful.
 * @callback successCb
 * @param {Object} data Data is passed to the success callback function
 */
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Retrieves the courses for a user
 * @kind function
 * @name getUserCourses
 * @memberof ibldmcourses
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {callback} successCb
 *
 * @example <caption>Get Courses for a user</caption>
 * This usage:
 *
 * ```js
 * ibldmcourses.getUserCourses(
 *    {username: "example"},
 *    (data) => console.log(data)
 * )
 * ```
 */
function getUserCourses(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_DM.GET_USER_COURSES, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
        errorCb && errorCb(error);
    }, true, 'IBL_DM_URL');
}
exports.getUserCourses = getUserCourses;
/**
 * Retrieves the progress details for a user
 * @kind function
 * @name fetchUserProgress
 * @memberof ibldmcourses
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {callback} successCb
 *
 * @example <caption>Get Progress for a user - example</caption>
 * This usage:
 *
 * ```js
 * ibldmcourses.fetchUserProgress(
 *    {username: "example"},
 *    (data) => console.log(data)
 * )
 * ```
 */
function fetchUserProgress(data, successCb = null) {
    http.get(config_1.default.IBL_DM.FETCH_USER_PROGRESS, Object.assign(Object.assign({}, data), { field_key: 'completion_percentage' }), (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.fetchUserProgress = fetchUserProgress;
/**
 * Retrieves user email invitation
 * @kind function
 * @name checkIfUserIsInvited
 * @memberof ibldmcourses
 *
 * @param {Object} data
 * @param {String} data.email User's email
 * @param {callback} successCb
 * @example <caption>Get User email invitaton for - hello@example.com</caption>
 * This usage:
 *
 * ```js
 * ibldmcourses.checkIfUserIsInvited(
 *    {username: "hello@example.com"},
 *    (data) => console.log(data)
 * )
 */
function checkIfUserIsInvited(data, successCb = null) {
    http.get(config_1.default.IBL_DM.CHECK_USER_INVITATION, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, false);
}
exports.checkIfUserIsInvited = checkIfUserIsInvited;
/**
 * Creates attendance for a user
 * @kind function
 * @name createAttendance
 * @memberof ibldmcourses
 *
 * @param {Object} data
 * @param {String} data.course_id ID of the course
 * @param {String} data.block_id ID of the block inside a course
 * @param {String} data.user_id edX user ID
 * @param {Boolean} [data.attendance=true] Course ID
 * @param {callback} successCb
 *
 * @example <caption>Create attendancer for user in course block</caption>
 * This usage:
 *
 * ```js
 * ibldmcourses.createAttendance(
 *    {
 *        course_id: "course-v1:IBL+1+1",
 *        block_id: "test-3",
 *        user_id: 3
 *    },
 *    (data) => console.log(data)
 * )
 */
function createAttendance(data, successCb = null) {
    http.post(config_1.default.IBL_DM.CREATE_USER_ATTENDANCE, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_LMS_URL');
}
exports.createAttendance = createAttendance;
/**
 * Retrieves the course completion for a user
 * @kind function
 * @name getUserCourseCompletion
 * @memberof ibldmcourses
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {String} data.course_id Course ID
 * @param {callback} successCb
 *
 * @example <caption>Get Course completion for a user</caption>
 * This usage:
 *
 * ```js
 * ibldmcourses.getUserCourseCompletion(
 *   {
 *        username: "example",
 *        course_id: "course-v1:IBL+1+1",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getUserCourseCompletion(data, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_USER_COURSE_COMPLETION, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.getUserCourseCompletion = getUserCourseCompletion;
/**
 * Retrieves the course skill point info
 * @kind function
 * @name getCourseSkillPointInfo
 * @memberof ibldmcourses
 *
 * @param {Object} data
 * @param {String} data.course_id Course ID
 * @param {callback} successCb
 *
 * @example <caption>Get the Course skill point info</caption>
 * This usage:
 *
 * ```js
 * ibldmcourses.getCourseSkillPointInfo(
 *   {
 *        course_id: "course-v1:IBL+1+1",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getCourseSkillPointInfo(data, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_COURSE_SKILL_POINT_INFO, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.getCourseSkillPointInfo = getCourseSkillPointInfo;
