import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDiscoverItemsAPI } from './helpers';

function useDiscover() {
  const [discoverItems, setDiscoverItems] = useState([]);
  const [discoverLoaded, setDiscoverLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [selectedTerms, setSelectedTerms] = useState([]);

  const queryParam = new URLSearchParams(location.search);
  const search = queryParam.get('q');

  useEffect(() => {
    setDiscoverLoaded(false);
    getDiscoverItemsAPI(discoverCallback, 1, selectedTerms, search);
  }, [search, page]);

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };
  return [
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
  ];
}

export default useDiscover;
